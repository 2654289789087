import type { TopSlideBanner } from "~/types";
import {
  plan,
  set_discounts,
  contest,
  review,
  mobi,
  campaign,
  mizu,
} from "@/constants/top/slideBanner";
import { prefToAlphabet } from "~/utils/sekou/area";
import { SEKOU_COUNT } from "~/constants/sekou";

export default function () {
  // 地域サイト用のスライドを生成
  const local_site = useLocalSite();
  const pref_key = prefToAlphabet(local_site.prefecture);
  const local_sekou: TopSlideBanner = {
    alt: "関西でNo1外構施工例実績。外構・リフォーム工事施工例を毎日更新中",
    text: {
      type: "sekou",
      value: addComma(SEKOU_COUNT),
      position: { x: 20, y: 74 },
    },
    path: "/images/banner/sekou.png",
    link: `/sekou/${pref_key}`,
    preload: true,
  };

  // 共通分とともに並べて配列に
  const slides: TopSlideBanner[] = [
    local_sekou,
    plan,
    set_discounts,
    contest,
    review,
    mobi,
    campaign,
    mizu,
  ];

  return slides;
}
